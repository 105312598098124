export default function (media, { orientation, width, height }) {
  const possiblePlacesToFindTheDamnImage = [
    media?.videoAsset?.attachments,
    media?.aggregatedVideo?.attachments,
    media?.images,
    media?.attachments,
    media?.content?.images,
    media?.series?.attachments,
    media?.tvShowSeries?.attachments,
    media?.tvShowSeason?.attachments,
  ];

  let imageObj = {};
  possiblePlacesToFindTheDamnImage.forEach((list) => {
    if (Object.values(imageObj).length > 0) return;
    let res;
    if (Array.isArray(orientation)) {
      //Prioritize image search in order in array mode
      for (let index = 0; index < orientation.length; index++) {
        res = list?.find((i) => i.name == orientation[index]);
        if (res) {
          break;
        }
      }
    } else {
      res = list?.find((i) => i.name == orientation);
    }
    Object.assign(imageObj, res);
  });

  let mediaUrl;
  if (process.server) {
    mediaUrl = process.env.IMG_HOST;
  } else {
    mediaUrl = useAppConfig().mediaUrl;
  }

  let url = `${mediaUrl}${imageObj?.value}`;

  if (width && height) {
    url += `?width=${width}&height=${height}`;
  }

  return url;
}
